import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CheckIcon from "@material-ui/icons/Check";
import { Container, Wrapper } from "./style";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ConfirmacaoAgendamento = () => {
  const navigate = useNavigate();

  return (
    <>
      <header className="App-header">
        <Header />
      </header>
      <Wrapper>
        <Container>
          <h1>Agendamento realizado com sucesso</h1>
          <h4 style={{ opacity: "0.5", fontSize: "20px" }}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </h4>
          <div>
            <CheckIcon
              style={{
                background: "green",
                borderRadius: "50%",
                fontSize: "40px",
                color: "#fff",
              }}
            />
          </div>
          <Button
            style={{ margin: "20px", background: "#e64c15", color: "#fff" }}
            onClick={() => navigate("/agendamentos")}
          >
            Minhas compras
          </Button>
        </Container>

        <Footer />
      </Wrapper>
    </>
  );
};
export default ConfirmacaoAgendamento;
