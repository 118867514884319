// services/index.js

import axios from "axios";
import { store } from "../store";
import { v4 } from "uuid";
import { clearToken } from "../store/token";
import { clearUser } from "../store/user";
import { clearShopCart } from "../store/shop-cart";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'http://localhost:8000/api',
  timeout: 30000,
});

export const handleError = (error: any) => {
  return Promise.reject(error);
};

instance.interceptors.request.use(
  async (request) => {
    const myUUID = v4();

    try {
      if (request.method === "get" && request.url?.includes("/seller/image")) {
      }
      if (
        (request.method === "delete" || request.method === "get") &&
        request.headers
      ) {
        request.headers["X-transaction-code"] = myUUID;
      }
      if (
        request.method === "post" ||
        request.method === "put" ||
        request.method === "patch"
      ) {
        request.data.transactionCode = myUUID;
      }
      if (
        !request.url?.endsWith("/user/create") &&
        !request.url?.endsWith("/authenticate")
      ) {
        const state = store.getState();

        const { data } = state.token;

        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${data}`,
        };
      }

      return request;
    } catch (error: any) {
      // exception to new token here

      return handleError(error);
    }
  },
  (error) => {
    return handleError(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response && error.code === "ECONNABORTED") {
      return handleError(error);
    }

    if (error?.response?.status) {
      const { status } = error.response;

      if (status === 401) {
        store.dispatch(clearToken());
        store.dispatch(clearUser());
        store.dispatch(clearShopCart());
      }

      if (status === 400 || status === 500) {
        return Promise.reject(error.response);
      }
    }

    return handleError(error);
  }
);

export default instance;
